<template>
  <div class="wrap">
    <p class="head">
      <span>{{ $t("filterByLeague") }}</span>
      <button @click="closeFilter"><CancelIcon /></button>
    </p>
    <section class="accordion">
      <div
        v-for="(tournaments, country, index) in groupedByCountry"
        :key="country"
      >
        <div
          class="head collapse"
          data-toggle="collapse"
          :data-target="`#collapse-${index + 1}`"
          aria-expanded="false"
          :aria-controls="`collapse-${index + 1}`"
        >
          <span>
            <span>{{ country }}</span>
          </span>
          <span style="float: right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="7"
              viewBox="0 0 14 7"
              fill="none"
            >
              <path
                d="M12.28 0.966797L7.9333 5.31346C7.41997 5.8268 6.57997 5.8268 6.06664 5.31346L1.71997 0.966797"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
        <div class="collapse body_contents" :id="`collapse-${index + 1}`">
          <div
            v-for="tournament in tournaments"
            :key="tournament.tournament_id"
            @click="handleSelectedCountry(tournament)"
          >
            <span>
              <CheckBoxIcon
                :active="checkedTournament.includes(tournament.tournament_id)"
              />
              <span class="check">{{ tournament.name }}</span></span
            >
            <span class="light">({{ tournament.games }}) </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CancelIcon from "../icons/CancelIcon.vue";
import CheckBoxIcon from "@/components/icons/CheckBoxIcon.vue";

export default {
  name: "LeagueSelector",
  components: {
    CancelIcon,
    CheckBoxIcon,
  },
  props: {
    actionType: {
      type: String,
      required: false,
    },
    groupedByCountry: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSlideUpOpen: false,
      timeCustom: false,
      selectedCountry: {},
      selectedTime: "",
      customType: null,
      selectedEndDate: "",
      selectedStartDate: "",
      competition_name: "Top League",
      league: {},
      countries: [],
    };
  },
  methods: {
    handleSelectedCountry(country) {
      const checked = [...this.$store.state.selectedTournament];
      const index = checked.indexOf(country.tournament_id);
      if (index > -1) {
        const res = checked.filter((check) => check != checked[index]);
        this.$store.dispatch("setSelectedTournament", res);
      } else {
        const data = [...checked, country.tournament_id];
        this.$store.dispatch("setSelectedTournament", data);
      }
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    handleReset() {
      this.selectedTime = "";
      this.selectedMarket = "";
      // this.checkedTournament = [];
      this.selectedStartDate = "";
      this.selectedEndDate = "";
      this.timeCustom = false;
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: null,
        tournament_id: null,
      };
      this.$store.dispatch("setMarketFilters", filters);
      this.$store.dispatch("setLeagueFilter", false);
    },
    closeFilter() {
      this.$store.dispatch("setLeagueFilter", false);
    },
  },
  computed: {
    checkedTournament: function () {
      const checked = this.$store.state.selectedTournament;
      return checked;
    },
  },
  watch: {
    fixtureFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.page = 1;
        // this.firstTimeLoading = true;
        // this.error = false;
        // this.clearData();
        // this.initMqtt();
        // this.initiateCall();
      }
    },
    checkedTournament(newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.page = 1;
        // this.firstTimeLoading = true;
        // this.error = false;
        // this.clearData();
        // this.initMqtt();
        // this.initiateCall();
      }
    },
    sport_id: function (newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) {
        // this.checkedTournament = this.$store.state.selectedTournament;
        // this.page = 1;
        // this.firstTimeLoading = true;
        // this.error = false;
        // this.resetFilters();
        // this.clearData();
        // this.initMqtt();
        // if (this.highlights || this.boosted || this.all || this.upcoming) {
        //   this.initiateCall();
        // } else {
        //   this.getCompetitions();
        // }
      }
    },
    market_id: function (newValue, oldValue) {
      this.clearData();
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }
      if (this.highlights || this.boosted || this.all || this.upcoming) {
        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.initMqtt();
        this.initiateCall();
      } else {
        if (this.visible_leagues.length > 0) {
          this.competitions = [];
          this.initMqtt();
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
    fetchSearchResults() {
      const searchResults = [];
      this.$emit("search-results", searchResults);
    },
    search: function (newValue, oldValue) {
      if (newValue !== oldValue && newValue.length > 0) {
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
        "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );
      this.getCompetitions();
    },
    competition_id: function (newValue, oldValue) {
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }
      if (this.highlights) {
        this.filterHighlight();
      } else {
        if (this.visible_leagues.length > 0) {
          this.competitions = [];
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  width: 100%;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-weight: 600;
}

.head > button {
  cursor: pointer;
  background-color: inherit !important;
  border: none !important;
}
.filter {
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 8px;
  gap: 8px;
  width: 100%;
}

.filter > button {
  width: 33%;
  border-radius: 4px;
  background: #f5f5f5;
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  color: #8c8787;
  span {
    color: #8c8787;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
}

.tablinks {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
}
.active {
  border-bottom: 1px solid #a31212;
}

.item {
  display: flex;
  padding: 16px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;

  & > span {
    color: var(--Light-Mode-Base-Base-900, #0e0d0d);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.accordion {
  /* outline: 1px solid blue; */
  /* width: 80; */
  /* height: 100vh; */
  /* max-width: 80vh; */
  /* overflow-y: scroll; */
}
.actions {
  /* outline: 1px solid red; */
  /* display: flex;
  gap: 4px;
  position: fixed;
  bottom: 0;
  left: 0; */
}
.accordion .head {
  background: var(--box-bg);
  border-bottom: 1px solid var(--fixture-border);
  display: flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.body_contents {
}
.body_contents > div {
  border-bottom: 1px solid var(--fixture-border);
  display: flex;
  padding: 8px 30px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  span {
    color: var(--text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    display: flex;
    align-items: center;
  }
}
.check {
  margin-left: 4px;
}
.light {
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

.actions {
  display: flex;
  gap: 4px;
}
</style>
